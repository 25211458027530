<template>
  <b-modal
    v-model="modalShow"
    size="lg"
    ok-title="Save"
    centered
    hide-footer
    @hidden="hide"
  >
    <template #modal-header>Product Serial Custom Object</template>
    <div class="content-modal">
      <template v-for="(rows, index) of customObjectFields">
        <template>
          <div :key="'tabs-' + index">
            <FieldRender
              :v="$v.customObjectFields"
              :field="rows"
              :n="index + 1"
              :index="index"
              @onAddImage="(image) => (rows.value = image)"
            />
          </div>
        </template>
      </template>
    </div>
    <b-row class="mt-3">
      <b-col cols="6">
        <b-button class="btn-cancel btn-width" variant="dark" @click="hide"
          >Cancel</b-button
        >
      </b-col>
      <b-col cols="6" class="text-sm-right">
        <b-button
          class="main-color btn-save btn-width"
          @click="submit"
          :disabled="isLoading"
        >
          Confirm
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import FieldRender from "./FieldRender";
import { required, email, requiredIf } from "vuelidate/lib/validators";
export default {
  components: { FieldRender },
  validations() {
    return {
      customObjectFields: {
        $each: {
          value: {
            required: requiredIf(function (item, val) {
              return item.is_required == 1;
            }),
          },
        },
      },
    };
  },
  data() {
    return { isLoading: false, modalShow: false, customObjectFields: [] };
  },
  methods: {
    hide() {
      this.modalShow = false;
    },
    show(id, obj, activeIndex) {
      this.$v.$reset();
      this.activeIndex = activeIndex != undefined ? activeIndex : null;
      this.modalShow = true;
      this.getFields(id, obj);
    },
    async getFields(id, obj) {
      const res = await this.axios(`/SerialProduct/serial_custom_object/${id}`);

      const getValue = (id, key, obj, field_type_id) => {
        if (!obj) {
          return key === "value" ? (field_type_id === 2 ? [] : "") : 0;
        }

        const field = obj.find((el) => el.serial_custom_object_field_id === id);
        let value = field ? field[key] : key === "value" ? "" : 0;

        if (key === "value") {
          if (field_type_id === 2) {
            return Array.isArray(value) ? value : value?.split(",") || [];
          }
        }

        return value;
      };

      this.customObjectFields = res.data.detail.fields.map((el) => {
        const defaultValue = el.field_choices
          .filter((fc) => fc.isDefault)
          .map((fc) => fc.id);
        let value = getValue(el.id, "value", obj, el.field_type_id);

        if (!value || (Array.isArray(value) && value.length === 0)) {
          value = el.field_type_id === 2 ? defaultValue : defaultValue[0] || "";
        }

        return {
          ...el,
          id: getValue(el.id, "id", obj),
          serial_custom_object_field_id: el.id,
          serial_custom_object_answer_id: getValue(
            el.id,
            "serial_custom_object_answer_id",
            obj
          ),
          value,
        };
      });
    },

    submit() {
      this.$v.$touch();
      // console.log(this.$v.$);
      if (this.$v.$error) return;
      this.modalShow = false;
      let answerValue = JSON.parse(JSON.stringify(this.customObjectFields));
      for (const field of answerValue) {
        if (field.field_type_id == 2) field.value;
      }

      this.$emit("add", this.customObjectFields, this.activeIndex);
    },
  },
};
</script>

<style>
.content-modal {
  height: 505px;
  background-color: white;
  overflow: hidden auto;
}
</style>
