<template>
  <div>
    <b-row class="no-gutters col-gap-lg row-gap-lg">
      <b-col cols="12" md="4">
        <b-card class="border-none rounded-none shadow-sm h-100" no-body>
          <div class="title-tabs fix-hight-50 content-between">
            <div>Variants ({{ variantList.length }}/3)</div>
            <b-button
              variant="custom-primary"
              size="sm"
              :disabled="variantList.length >= 3"
              @click="addVariant"
              v-if="$route.params.id == 0 || noVariant"
            >
              <font-awesome-icon icon="plus-square"></font-awesome-icon> Add
              Variant
            </b-button>
          </div>
          <div
            class="variant-content"
            :class="{ empty: variantList.length === 0 }"
          >
            <template v-if="variantList.length === 0">
              No Data. Please add variant
            </template>
            <template v-else>
              <b-row class="row-gap-lg">
                <b-col
                  v-for="(variant, index) of variantList"
                  :key="'variant' + index"
                  cols="12"
                >
                  <b-card
                    class="border-none shadow-sm py-2 px-2 variant-list"
                    :class="{ active: index === activeVariant }"
                    no-body
                    @click="activeVariant = index"
                  >
                    <div class="content-between">
                      <span class="text-bold">
                        {{ variant.attribute.display_name }}
                      </span>
                      <font-awesome-icon
                        v-if="!variant.attribute.code"
                        icon="trash-alt"
                        class="cursor-pointer"
                        @click="removeVariant(index)"
                      ></font-awesome-icon>
                    </div>
                    <div>
                      {{
                        variant.options.map((el) => el.display_name).join(", ")
                      }}
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="auto" style="flex: 1">
        <b-card class="border-none rounded-none shadow-sm" no-body>
          <div class="title-tabs fix-hight-50">Detail</div>
          <div
            class="variant-content"
            :class="{ empty: variantList.length === 0 }"
          >
            <template v-if="variantList.length === 0">
              No Data. Please add variant
            </template>
            <template v-else>
              <div class="w-custom-50">
                <div
                  :class="{
                    'd-flex': variantList[activeVariant].attribute.id === 0,
                  }"
                >
                  <InputSelect
                    :class="{
                      'w-50': variantList[activeVariant].attribute.id === 0,
                    }"
                    title="Variant Name"
                    name="Order Status"
                    :options="variantNameList"
                    v-model="variantList[activeVariant].attribute.id"
                    valueField="id"
                    textField="name"
                    :disabled="
                      variantList[activeVariant].attribute.code ? true : false
                    "
                    isRequired
                    @onDataChange="(val) => onChangeVariant(val, activeVariant)"
                  >
                  </InputSelect>

                  <template
                    v-if="variantList[activeVariant].attribute.id === 0"
                  >
                    <InputText
                      :ref="'variant-' + activeVariant"
                      :name="'variant-' + activeVariant"
                      class="mt-4 pl-3 w-100"
                      textFloat=""
                      placeholder="Variant name"
                      :maxLength="100"
                      v-model="
                        variantList[activeVariant].attribute.display_name
                      "
                      :isValidate="
                        isErrorState &&
                        variantList[activeVariant].attribute.display_name == ''
                      "
                    />
                  </template>
                </div>

                <div class="variable-list">
                  <div class="mb-3">Options</div>
                  <template
                    v-for="(variable, index) of variantList[activeVariant]
                      .options"
                  >
                    <div
                      :key="'options-' + variable.name + index"
                      class="d-flex align-items-center mb-3"
                    >
                      <div style="width: 20px">
                        {{ index + 1 }}
                      </div>
                      <div
                        :class="{
                          'content-between': variable.id === 0,
                        }"
                        class="w-100"
                      >
                        <InputSelect
                          :class="{
                            'w-50': variable.id === 0,
                            'w-100': variable.id !== 0,
                            'pr-3': variable.id !== 0,
                          }"
                          class="pl-3 mb-0"
                          title=""
                          name="Order Status"
                          :options="filterOptions"
                          v-model="variable.id"
                          valueField="id"
                          textField="name"
                          @onDataChange="(val) => onChangeOptions(val, index)"
                          isRequired
                          :disabled="variable.code ? true : false"
                        >
                        </InputSelect>
                        <template v-if="variable.id == 0">
                          <InputText
                            :ref="'options-' + index + '-' + activeVariant"
                            :name="'optionsa-' + index + '-' + activeVariant"
                            class="px-3 mb-0 w-100"
                            textFloat=""
                            placeholder="Variable"
                            v-model="variable.display_name"
                            @input="clearField"
                            :isValidate="
                              isErrorState && variable.display_name == ''
                            "
                          />
                        </template>
                      </div>

                      <font-awesome-icon
                        v-if="
                          !variable.code &&
                          variantList[activeVariant].options.length > 1
                        "
                        icon="trash-alt"
                        class="cursor-pointer"
                        @click="removeOptions(index)"
                      ></font-awesome-icon>
                    </div>
                  </template>
                </div>
                <div
                  class="text-underline cursor-pointer mt-3"
                  @click="addOptions"
                >
                  Add Options
                </div>
              </div>
            </template>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card class="border-none rounded-none shadow-sm" no-body>
          <div class="title-tabs fix-hight-50">Variable</div>
          <div
            class="variable-content"
            :class="{ empty: variantList.length === 0 }"
          >
            <template v-if="variantList.length === 0">
              No Data. Please add variant
            </template>
            <template v-else>
              <b-table
                responsive
                hover
                striped
                :fields="fields"
                :items="variableItems"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(expand)="row">
                  <div v-if="row.item.options.length > 0">
                    <font-awesome-icon
                      v-if="!row.detailsShowing"
                      @click="row.toggleDetails"
                      icon="chevron-down"
                      class="cursor-pointer"
                      size="sm"
                    />
                    <font-awesome-icon
                      v-else
                      @click="row.toggleDetails"
                      icon="chevron-up"
                      class="cursor-pointer"
                      size="sm"
                    />
                  </div>
                </template>
                <template v-slot:cell(display_name)="row">
                  {{ variantList[0].attribute.display_name }}
                </template>
                <template v-slot:cell(price)="row">
                  <template v-if="row.item.options.length == 0">
                    <InputText
                      :ref="'input-' + row.index + '-' + row.item.id"
                      class="mb-0"
                      textFloat=""
                      :name="'price' + row.index + '-' + row.item.id"
                      placeholder="Price"
                      @blur="handleBlurBath([row.index])"
                      :value="cloneVariable[row.index].price"
                      @input="
                        (val, $event) =>
                          onChangePrice(
                            val,
                            row.item.id,
                            null,
                            'price',
                            $event,
                            [row.index]
                          )
                      "
                      useDot
                      useSub
                      @onKeypress="handleKeyPress"
                      :isValidate="
                        isErrorState && cloneVariable[row.index].price == ''
                      "
                    />
                  </template>
                </template>
                <template v-slot:cell(barcode)="row">
                  <template v-if="row.item.options.length == 0">
                    <InputText
                      :ref="'input-barcode-' + row.index + '-' + row.item.id"
                      :name="'barcode' + row.index + '-' + row.item.id"
                      class="mb-0"
                      textFloat=""
                      :value="cloneVariable[row.index].barcode"
                      placeholder="Barcode"
                      @input="
                        (val, $event) =>
                          onChangePrice(
                            val,
                            row.item.id,
                            null,
                            'barcode',
                            $event,
                            [row.index]
                          )
                      "
                      :isValidate="
                        isErrorState && cloneVariable[row.index].barcode == ''
                      "
                  /></template>
                </template>
                <template #row-details="row">
                  <div class="p-3 bg-white">
                    <b-table
                      responsive
                      hover
                      striped
                      :fields="fields2"
                      :items="row.item.options"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:cell(display_name)="row">
                        {{ row.item.display_name }}
                      </template>
                      <template v-slot:cell(price)="data">
                        <InputText
                          :ref="
                            'input-row-price-' +
                            row.index +
                            '-' +
                            data.item.ref_product_id
                          "
                          :name="
                            'row-price' +
                            row.index +
                            '-' +
                            data.item.ref_product_id
                          "
                          textFloat=""
                          placeholder="Price"
                          @blur="handleBlurBath([row.index, data.index])"
                          :value="
                            cloneVariable[row.index].options[data.index].price
                          "
                          @onKeypress="handleKeyPress"
                          @input="
                            (val, $event) =>
                              onChangePrice(
                                val,
                                row.item.id,
                                data.item.ref_product_id,
                                'price',
                                $event,
                                [row.index, data.index]
                              )
                          "
                          :isValidate="
                            isErrorState &&
                            cloneVariable[row.index].options[data.index]
                              .price == ''
                          "
                        />
                        <!-- v-model="item.price" -->
                      </template>
                      <template v-slot:cell(barcode)="data">
                        <InputText
                          textFloat=""
                          :ref="
                            'input-row-barcode1-' +
                            row.index +
                            '-' +
                            data.item.ref_product_id
                          "
                          :name="
                            'row-barcode1' +
                            row.index +
                            '-' +
                            data.item.ref_product_id
                          "
                          placeholder="Barcode"
                          :value="
                            cloneVariable[row.index].options[data.index].barcode
                          "
                          @input="
                            (val, $event) =>
                              onChangePrice(
                                val,
                                row.item.id,
                                data.item.ref_product_id,
                                'barcode',
                                $event,
                                [row.index, data.index]
                              )
                          "
                          :isValidate="
                            isErrorState &&
                            cloneVariable[row.index].options[data.index]
                              .barcode == ''
                          "
                        />
                      </template>
                    </b-table>
                  </div>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </template>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AutoComplete from "@/components/inputs/Autocomplete";
export default {
  components: { AutoComplete },
  name: "ProductVariant",
  data() {
    return {
      noVariant: false,
      variantList: [],
      variantNameList: [
        { id: 1, name: "Color" },
        { id: 2, name: "Size" },
        { id: 3, name: "Gender" },
        { id: 0, name: "Add More" },
      ],

      activeVariant: 0,

      fields: [
        { key: "expand", label: "", class: "width-100px" },
        {
          key: "display_name",
          label: "Variant Name",
          // thClass: "upper",
        },
        {
          key: "name",
          label: "Variant",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "barcode",
          label: "Barcode",
        },
      ],
      fields2: [
        { key: "expand", label: "", class: "width-100px" },
        {
          key: "display_name",
          label: "Variant Name",
          // thClass: "upper",
        },
        {
          key: "name",
          label: "Variant",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "barcode",
          label: "Barcode",
        },
      ],
      items: [],
      items2: [],
      isBusy: false,
      cloneVariable: [],
      validateFieldsState: false,
      isErrorState: false,
    };
  },
  watch: {
    variantList(val) {
      if (val.length > 1)
        this.fields = [
          { key: "expand", label: "", class: "width-100px" },
          {
            key: "display_name",
            label: "Variant Name",
            // thClass: "upper",
          },
          {
            key: "name",
            label: "Variant",
          },
          {
            key: "price",
            label: "",
          },
          {
            key: "barcode",
            label: "",
          },
        ];
      else
        this.fields = [
          { key: "expand", label: "", class: "width-100px" },
          {
            key: "display_name",
            label: "Variant Name",
            // thClass: "upper",
          },
          {
            key: "name",
            label: "Variant",
          },
          {
            key: "price",
            label: "Price",
          },
          {
            key: "barcode",
            label: "Barcode",
          },
        ];
    },
  },
  computed: {
    optionsList() {
      if (this.variantList[this.activeVariant].attribute) {
        let options = this.variantNameList.find(
          (el) => el.id == this.variantList[this.activeVariant].attribute.id
        );

        if (options)
          return options.options_list
            ? [
                { id: 0, name: "Add More", display_name: "" },
                ...options.options_list,
              ]
            : [{ id: 0, name: "Add More", display_name: "" }];
      }
      return [{ id: 0, name: "Add More", display_name: "" }];
    },
    filterOptions() {
      return this.optionsList.map((el) => {
        return {
          ...el,
          disabled:
            el.id == 0
              ? false
              : this.variantList[this.activeVariant].options.find(
                  (item) => item.id == el.id
                ),
        };
      });
    },
    variableItems() {
      let _template = JSON.parse(JSON.stringify(this.cloneVariable));
    
      if (!this.variantList.length) return [];
      const getProductVitual = (id, V1_index) => {
        let detail = [];

        if (this.variantList[1]) {
          const variant1 = this.variantList[1];

          detail = variant1.options.map((el, OPT_index) => ({
            id: el.id,
            display_name: variant1.attribute.display_name,
            product_id: this.getPrice(id, [el.id], "product_id", _template, [
              V1_index,
              OPT_index,
            ]),
            name: el.id == 0 ? el.display_name : el.name,
            price: this.getPrice(id, [el.id], "price", _template, [
              V1_index,
              OPT_index,
            ]),
            options: [el],
            barcode: this.getPrice(id, [el.id], "barcode", _template, [
              V1_index,
              OPT_index,
            ]),
            detailsShowing: false,
            ref_product_id: [el.id],
          }));
        }
        if (this.variantList[2]) {
          const variant2 = this.variantList[2];
          var n = 0;
          detail = detail.flatMap((element) => {
            return variant2.options.map((sub, OPT_index) => {
              let temp = {
                id: sub.id,
                product_id: this.getPrice(
                  id,
                  [element.id, , sub.id],
                  "product_id",
                  _template,
                  [V1_index, n]
                ),
                display_name: `${this.variantList[1].attribute.display_name} / ${variant2.attribute.display_name}`,
                name: `${element.name} / ${sub.display_name}`,
                price: this.getPrice(
                  id,
                  [element.id, sub.id],
                  "price",
                  _template,
                  [V1_index, n]
                ),
                options: [element, sub],
                barcode: this.getPrice(
                  id,
                  [element.id, sub.id],
                  "barcode",
                  _template,
                  [V1_index, n]
                ),
                detailsShowing: false,
                ref_product_id: [element.id, sub.id],
              };
              n++;
              return temp;
            });
          });
        }
        return detail;
      };
      const variant0 = this.variantList[0];

      const variableValue = variant0.options.map((el, index) => ({
        id: el.id,
        display_name:
          el.id == 0 ? variant0.attribute.display_name : el.display_name,
        name: el.display_name,
        price: this.getPrice(el.id, null, "price", _template, [index]),
        options: getProductVitual(el.id, index),
        barcode: this.getPrice(el.id, null, "barcode", _template, [index]),
        product_id:
          this.getPrice(el.id, null, "product_id", _template, [index]) || 0,
        _showDetails: this.variantList[1] ? true : false,
      }));

      this.cloneVariable = JSON.parse(JSON.stringify(variableValue));

      return variableValue;
    },
  },
  created() {
    this.getVariant();
  },
  methods: {
    addVariant() {
      const index =
        this.variantNameList.length > 1
          ? this.variantList.length + 1
          : this.variantList.length;
      this.variantList.push({
        attribute: {
          id: 0,
          name: "Add More",
          display_name: "Add More",
          display_id: 0,
        },
        options: [
          {
            id: 0,
            name: "Add More",
            display_name: "Add More",
          },
        ],
      });
      this.activeVariant = this.variantList.length - 1;
    },
    removeVariant(index) {
      this.activeVariant = this.activeVariant != 0 ? this.activeVariant - 1 : 0;
      this.$nextTick(() => this.variantList.splice(index, 1));
    },
    addOptions() {
      this.variantList[this.activeVariant].options.push({
        id: 0,
        name: "Add More",
        display_name: "Add More",
      });
    },
    removeOptions(index) {
      this.variantList[this.activeVariant].options.splice(index, 1);
    },
    onChangeOptions(items, index) {
      let name = this.optionsList.find((el) => el.id == items);

      this.variantList[this.activeVariant].options[index].name = name.name;
      this.variantList[this.activeVariant].options[index].display_name =
        name.name;
    },
    onChangeVariant(items, index) {
      let name = this.variantNameList.find((el) => el.id == items);

      this.variantList[this.activeVariant].attribute.id = name.id;
      this.variantList[this.activeVariant].attribute.name = name.name;
      this.variantList[this.activeVariant].attribute.display_name = name.name;
      this.variantList[this.activeVariant].options = [
        {
          id: 0,
          name: "Add More",
          display_name: "Add More",
        },
      ];
    },
    onPageChange() {},
    pagination() {},
    async getVariant() {
      const res = await this.axios("/ProductMenu/get_master_vatiants");

      this.variantNameList = [
        { id: 0, name: "Add More", display_name: "" },
        ...res.data.detail.map((el) => {
          return {
            id: el.attribute.id,
            name: el.attribute.name,
            options_list: el.options,
          };
        }),
      ];
    },
    async getVariantDetail() {
      const res = await this.axios(
        "/ProductMenu/get_config_product_detail/" + this.$route.params.id
      );

      const transformTemp = (data) => {
        return data.map((el) => ({
          attribute: {
            id: el.attribute.id,
            name: el.attribute.name,
            display_name: el.attribute.name,
            code: el.attribute.code,
          },
          options: el.options.map((opt) => ({
            id: opt.id,
            name: opt.name,
            display_name: opt.name,
            code: opt.code,
          })),
        }));
      };
      const variant = transformTemp(res.data.detail.variants);

      this.noVariant = res.data.detail.variants.length == 0;
      this.variantList = variant;
      this.items = res.data.detail.virtual_products.map((el) => {
        return { ...el, ref_product_id: el.options.map((el) => el.id) };
      });
      return res.data;
    },

    setUPVariantItems() {
      const fixVariantOptions = (options) =>
        options.map((option) => ({
          ...option,
          name: option.id === 0 ? option.display_name : option.name,
        }));
      const temp = JSON.parse(JSON.stringify(this.variantList));
      const variants = temp.map((el) => ({
        attribute: {
          id: el.attribute.id,
          name:
            el.attribute.id === 0
              ? el.attribute.display_name
              : el.attribute.name,
        },
        options: fixVariantOptions(el.options),
      }));

      let virtual_products = [];

      const tempClone = JSON.parse(JSON.stringify(this.cloneVariable));
      for (const e of tempClone) {
        if (e.options.length == 0) {
          virtual_products.push({
            product: {
              product_id: e.product_id || 0,
              name: e.name,
              price: e.price,
              barcode: e.barcode,
            },
            options: [
              {
                id: e.id,
                name: e.id == 0 ? e.name : e.display_name,
              },
            ],
          });
        } else
          for (const a of e.options) {
            let main_options = {
              id: e.id,
              name: e.id == 0 ? e.name : e.display_name,
            };
            virtual_products.push({
              product: {
                product_id: a.product_id || 0,
                name: e.name + " / " + a.name,
                price: Number(a.price),
                barcode: a.barcode,
              },
              options: [
                main_options,
                ...a.options.map((el, index) => {
                  return {
                    id: el.id,
                    name:
                      index == 0 && a.options.length > 1
                        ? el.name
                        : el.id == 0
                        ? el.display_name
                        : el.name,
                    display_name: el.display_name,
                  };
                }),
              ],
            });
          }
      }

      return { variants, virtual_products };
    },

    getPrice(id, ref_id, key, list, index) {
      if (list.length > 0) {
        if (index.length > 1) {
          if (
            list[index[0]] &&
            list[index[0]].options &&
            list[index[0]].options[index[1]]
          ) {
            return list[index[0]].options[index[1]][key];
          }
        }
        if (index.length === 1) {
          if (list[index[0]]) {
            return list[index[0]][key];
          }
        }
      }

      let obj;
      if (ref_id) {
        obj = this.items.find(function (el) {
          return el.ref_product_id.every(function (ref) {
            return [id].concat(ref_id).includes(ref);
          });
        });
      } else {
        obj = this.items.find(function (el) {
          return el.ref_product_id.every(function (ref) {
            return [id].includes(ref);
          });
        });
      }

      if (!obj) {
        return "";
      }

      return key === "price" ? obj.product[key].toFixed(2) : obj.product[key];
    },
    onChangePrice(value, id, ref_id, key, e, index) {
      if (value) e.target.classList.remove("error");

      if (this.variantList.length > 1) {
        this.cloneVariable[index[0]].options[index[1]][key] = value;
      } else {
        this.cloneVariable[index[0]][key] = value;
      }
    },
    handleBlurBath(index) {
      let value = "";
      if (this.variantList.length > 1) {
        value = this.cloneVariable[index[0]].options[index[1]].price;
      } else {
        value = this.cloneVariable[index[0]].price;
      }
      value = String(value);

      if (!value.includes(".") && value) {
        if (this.variantList.length > 1) {
          this.cloneVariable[index[0]].options[index[1]].price += ".00";
        } else {
          this.cloneVariable[index[0]].price += ".00";
        }
      } else {
        let parts = value.split(".");
        if (parts[1].length === 1) {
          if (this.variantList.length > 1) {
            this.cloneVariable[index[0]].options[index[1]].price += "0";
          } else {
            this.cloneVariable[index[0]].price += "0";
          }
        }
      }
    },
    async validateFields() {
      var error = false;

      if (this.variableItems.length == 0) return error;
      const { variants, virtual_products } = await this.setUPVariantItems();

      var i = 0;
      for (const variant of variants) {
        if (variant.attribute.name.trim() == "") {
          this.activeVariant = i;
          this.isErrorState = true;
          return (error = true);
        }
        for (const option of variant.options) {
          if (option.name.trim() == "") {
            this.activeVariant = i;
            this.isErrorState = true;
            return (error = true);
          }
        }
        i++;
      }
      for (const { product } of virtual_products) {
        if (
          String(product.price).trim() == "" ||
          product.barcode.trim() == ""
        ) {
          this.isErrorState = true;
          return (error = true);
        }
      }
      this.isErrorState = error;
      let elementFocus = null;
      await this.$nextTick();
      return this.isErrorState;
    },
    clearField(value, e) {
      if (value) e.target.classList.remove("error");
    },
    handleKeyPress(event) {
      const char = event.key;
      const value = event.target.value;
      const cursorPos = event.target.selectionStart;

      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && value.includes(".")) ||
        (char === "," && value.includes(","))
      ) {
        event.preventDefault();
      }

      const decimalIndex = value.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPlaces = value.length - decimalIndex - 1;
        if (decimalPlaces >= 2 && cursorPos > decimalIndex) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.variant-content {
  min-height: 300px;
  height: 100%;
  padding: 1rem;
  color: black;
  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.variant-list {
  cursor: pointer;
  border-left: 0.45rem solid transparent;
  &.active {
    border-left: 0.45rem solid var(--primary-color);
  }
}
.variable-content {
  color: black;
  // min-height: 300px;
  height: 100%;
  padding: 1rem;
  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.variable-list {
  max-height: calc(45px * 6.7);
  overflow: auto;
  padding-right: 0.75rem;
}
.fix-hight-50 {
  height: 50px;
}
.width-100px {
  width: 100px;
}
</style>
