<template>
  <div :key="'fields-' + index + n" class="mb-3" :id="field.field_type_id">
    <template v-if="field.field_type_id == 1">
      <InputText
        :textFloat="field.name"
        :placeholder="field.name"
        v-model="field.value"
        :isValidate="v && v.$each.$iter[index].value.$error"
        :isRequired="field.is_required == 1 ? true : false"
        class="mb-0"
      />
    </template>

    <template v-else-if="field.field_type_id == 2">
      <label class="main-label" :for="'checkbox-' + n + index">
        {{ field.name }}
        <span v-if="field.is_required == 1 ? true : false" class="text-error"
          >*</span
        >
      </label>
      <b-form-checkbox-group
        :state="v && !v.$each.$iter[index].value.$error"
        :id="'checkbox-' + n + index"
        v-model="field.value"
      >
        <b-form-checkbox
          v-for="choice of field.field_choices"
          :value="choice.id"
          :key="choice.name + choice.id"
          >{{ choice.name }}</b-form-checkbox
        >
      </b-form-checkbox-group>
    </template>
    <template v-else-if="field.field_type_id == 3">
      <label class="main-label" :for="'radio-' + n + index">
        {{ field.name }}
        <span v-if="field.is_required == 1 ? true : false" class="text-error"
          >*</span
        >
      </label>

      <b-form-radio-group
        :id="'radio-' + n + index"
        v-model="field.value"
        :options="field.field_choices"
        :name="'radio-' + n + index"
        value-field="id"
        text-field="name"
      ></b-form-radio-group>
    </template>
    <template v-else-if="field.field_type_id == 4">
      <InputSelect
        :title="field.name"
        :options="field.field_choices"
        value-field="id"
        text-field="name"
        :name="'select-' + n + index"
        v-model="field.value"
        :isValidate="v && v.$each.$iter[index].value.$error"
        :isRequired="field.is_required == 1 ? true : false"
      >
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select {{ field.name }} --</b-form-select-option
          >
        </template>
      </InputSelect>
    </template>
    <template v-else-if="field.field_type_id == 5">
      <InputDatePickerFilter
        :isRequired="field.is_required == 1 ? true : false"
        :textFloat="field.name"
        type="datetime"
        :name="'date-' + n + index"
        :ref="'date-' + n + index"
        v-model="field.value"
        format="dd/MM/yyyy (HH:mm)"
        :isValidate="v && v.$each.$iter[index].value.$error"
      />
      <!-- <FieldDateTime :field="field" /> -->
    </template>
    <template v-else-if="field.field_type_id == 6">
      <InputSelect
        :title="field.name"
        :options="field.branch_list"
        value-field="id"
        text-field="name"
        :name="'select-' + n + index"
        v-model="field.value"
        :isValidate="v && v.$each.$iter[index].value.$error"
        :isRequired="field.is_required == 1 ? true : false"
      >
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select {{ field.name }} --</b-form-select-option
          >
        </template>
      </InputSelect>
    </template>
    <template v-else-if="field.field_type_id == 7">
      <InputDatePickerFilter
        :isRequired="field.is_required == 1 ? true : false"
        :textFloat="field.name"
        :name="'datetime-' + n + index"
        :ref="'datetime-' + n + index"
        v-model="field.value"
        :isValidate="v && v.$each.$iter[index].value.$error"
      />
      <!-- <FieldDate :field="field" /> -->
    </template>
    <template v-else-if="field.field_type_id == 8">
      <UploadFile
        v-model="field.value"
        :value="field.value"
        :textFloat="field.name"
        :placeholder="field.name"
        format="image"
        name="thumbnail"
        :isRequired="field.is_required == 1 ? true : false"
        accept="image/jpeg, image/png"
        text=""
        :isValidate="v && v.$each.$iter[index].value.$error"
        v-on:onFileChange="($event) => onImageChange($event, index, n)"
        selectFileText=""
        :fileName="field.value"
      />
      <div v-if="field.value">
        <PreviewBox
          v-on:delete="onDeleteImage(index)"
          :disabled="field.isLoading"
          :showPreviewType="0"
          :showPreview="field.value"
          :isLoadingImage="field.isLoading"
          :ratioType="1"
          small
          class="mb-3"
        />
      </div>
    </template>
    <template v-else-if="field.field_type_id == 12">
      <InputTextArea
        :isRequired="field.is_required == 1 ? true : false"
        :placeholder="field.name"
        :textFloat="field.name"
        v-model="field.value"
        :isValidate="v && v.$each.$iter[index].value.$error"
      />
    </template>
    <div v-if="v && v.$each.$iter[index].value.$error" class="text-error">
      {{ field.validate_text }}
    </div>
  </div>
</template>

<script>
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: { PreviewBox },
  props: {
    field: { required: true },
    form: { required: false },
    // fieldsIndex: { required: true },
    v: { required: false, default: null },
    n: { required: false },
    index: { required: false },
  },
  methods: {
    showImage(imageUrl) {
      this.$refs[`${this.depth}-modalPreviewImage`].show(imageUrl);
    },
    onImageChange(img, index, n) {
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        const images = await this.saveImagetoDb(reader.result);
        // this.isLoadingImage = false;
        // this.isDisable = false;
        this.$emit("onAddImage", images);

        this.$forceUpdate();
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      console.log((this.field.value = ""));
      // this.form.image_url = "";
      // this.showPreview = "";
    },
  },
};
</script>

<style></style>
