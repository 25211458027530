<template>
  <div class="bg-white p-2 p-lg-3">
    <div class="mb-3">
      <b-input-group>
        <b-form-input
          style="max-width: 30%"
          class="search-bar"
          @keyup.enter="handleSearch"
          placeholder="Search Name, Member ID, Tel., Email"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button
          v-b-toggle.sidebar-filter
          class="btn button btn-mobile px-4 ml-auto"
        >
          <font-awesome-icon
            icon="filter"
            title="filter-btn"
            class="main-color mr-0 mr-sm-1"
          />
          <span class="d-none d-sm-inline">Filter</span>
        </b-button>
      </b-input-group>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(stock_type_name)="{ item }"
        >{{ item.stock_type_name || "-" }}
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            switch
            v-model="item.status"
            class="radio-active px-4"
            size="lg"
            :value="1"
            :unchecked-value="0"
            @change="handleChangeStatus(item.branch_id, item.status)"
          >
          </b-form-checkbox>

          <b-button
            variant="link"
            class="text-bg-primary px-1 py-0"
            @click="openModal(item)"
          >
            <font-awesome-icon
              style="transform: scale(1.2)"
              icon="sliders-h"
              title="Inventory"
            />
          </b-button>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <ModalEditStock ref="modal-edit-stock" @getData="getInventory" />
    <b-sidebar
      id="sidebar-filter"
      v-model="sidebarShow"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
      aria-labelledby="sidebar-filter-title"
      no-header
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Filter</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="clearFilter">
              x <span class="text-under">Clear Filter</span>
            </button>
          </b-col>
        </b-row>

        <template v-for="(filter, index) of options">
          <div :key="'input-' + filter.attribute.id" class="mt-3">
            <label for="" class="main-label">{{ filter.attribute.name }}</label>
            <multiselect
              id="status"
              v-model="selectedValue[index].value"
              trackBy="id"
              label="name"
              :placeholder="`Select ${filter.attribute.name} to search`"
              open-direction="bottom"
              :options="filter.options"
              :multiple="true"
              :searchable="false"
              :internal-search="true"
              :limit="10"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="false"
              @select="onSelect($event, 1)"
              @remove="onSelect($event, 0)"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              @click.prevent="hide"
              variant="custom-primary"
              class="rounded-none"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              class="rounded-none"
              variant="custom-outline-primary"
              @click.prevent="submitFilter"
              block
              id="handle-search-filter"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ModalEditStock from "@/views/pages/manageBranch/components/ModalEditStock.vue";

export default {
  components: {
    ModalEditStock,
    Multiselect,
  },
  props: { isVariant: { required: false } },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      options: [],
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
          thClass: "w-2",
        },
        {
          key: "branch_name",
          label: "Branch Name",
          thClass: "w-2",
        },

        {
          key: "branch_group",
          label: "Branch Group",
        },
        {
          key: "stock_type_name",
          label: "Stock",
        },
        {
          key: "custom_1",
          label: "",
        },
        {
          key: "custom_2",
          label: "",
        },
        {
          key: "custom_3",
          label: "",
        },
        {
          key: "quantity",
          label: "Quantity",
        },

        {
          key: "actions",
          label: "Active",
          thClass: "w-1",
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        option_id: [],
        product_id: Number(this.$route.params.id),
      },
      deleteImageList: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isFetch: false,
      tabLoading: true,
      isGetProductField: false,
      sidebarShow: false,
      selectedValue: null,
    };
  },
  methods: {
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        option_id: [],
        product_id: Number(this.$route.params.id),
      };
    },
    hide() {
      this.sidebarShow = false;
    },
    submitFilter() {
      this.hide();
      this.getInventory();
    },
    openModal(items) {
      // items.product_id = Number(this.id);
      this.$refs["modal-edit-stock"].show(items);
    },

    handleSearch() {
      this.getInventory();
    },

    async getInventory() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/Product/GetProductInventory`,
        this.filter
      );
      function transformData(data) {
        return data.flatMap((item) =>
          item.options.map((option) => ({
            ...option,
            key: item.attribute.name,
          }))
        );
      }
      if (!this.selectedValue)
        this.selectedValue = res.data.detail.variants.map((el) => {
          return { value: [] };
        });
      if (this.isVariant) {
        this.options = res.data.detail.variants;
        if (res.data.detail.variants.length > 0) {
          const variant = transformData(res.data.detail.variants);
          for (const element of res.data.detail.data) {
            for (const opt of element.options) {
              let key = variant.find((e) => e.id == opt.id);

              element[key.key] = opt.name;
            }
          }
          let i = 4;
          for (const element of res.data.detail.variants) {
            this.fields[i].label = element.attribute.name;
            this.fields[i].key = element.attribute.name;
            i++;
          }
        }
      }
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;

      this.fields = this.fields.filter((el) => el.label);
      this.isFetch = true;
      this.isBusy = false;
    },
    pagination(val) {
      this.filter.page = val;
      this.getInventory();
    },
    handleChangeTake() {
      this.getInventory();
    },
    async handleChangeStatus(id, value) {
      const res = await this.axios.post(`/Product/SwitchInventoryStatus`, {
        ProductId: this.filter.product_id,
        BranchId: id,
        newStatus: value,
      });
    },
    onSelect(val, type) {
      if (type) this.filter.option_id.push(val.id);
      else
        this.filter.option_id.splice(this.filter.option_id.indexOf(val.id), 1);
      console.log(val, type);
    },
  },
};
</script>
<style scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0.5rem;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
</style>
