<template>
  <b-modal
    v-model="modalShow"
    size="lg"
    ok-title="Save"
    centered
    hide-footer
    @hidden="hide"
  >
    <template #modal-header>Product Serial Detail</template>
    <div v-if="isLoading" class="fix-height d-flex justify-content-center">
      <OtherLoading />
    </div>
    <div v-else>
      <b-tabs
        content-class="my-3 content-tabs"
        v-model="tabIndex"
        nav-class="nav-container"
      >
        <b-tab title="General Information">
          <b-row>
            <b-col class="col-12">
              <SelectBranch
                textFloat="Branch Name"
                v-model="form.branch_id"
                :value="form.branch_id"
                @input="handleSelectBranch"
                valueField="id"
                textField="name"
                :isValidate="$v.form.branch_id.$error"
                :v="$v.form.branch_id"
                isRequired
                :disabled="serial_id !== 0"
              />
            </b-col>
            <b-col class="col-6">
              <InputText
                v-model="form.serial"
                isRequired
                textFloat="Serial"
                placeholder="Serial"
                :isValidate="$v.form.serial.$error"
                :v="$v.form.serial"
                @onKeypress="onKeypressText"
                type="text"
              ></InputText>
            </b-col>
            <b-col class="col-6">
              <InputText
                v-model="form.price"
                isRequired
                textFloat="Price"
                placeholder="Price"
                :isValidate="$v.form.price.$error"
                :v="$v.form.branch_id"
                :useDot="false"
                type="number"
              ></InputText>
            </b-col>
            <b-col class="col-6">
              <InputText
                v-model="form.weight"
                textFloat="Weight"
                placeholder="Weight"
                :useDot="false"
                type="number"
              ></InputText>
            </b-col>
            <b-col class="col-6">
              <InputSelect
                placeholder="Stock"
                title="Stock"
                isRequired
                v-model="form.stock_type_id"
                valueField="id"
                textField="name"
                :isValidate="$v.form.stock_type_id.$error"
                :v="$v.form.stock_type_id"
                :options="listStock"
                :disabled="!form.branch_id || serial_id != 0"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="0" disabled
                    >-- Select Stock --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </b-col>
            <b-col class="col-6" v-if="isVariant">
              <InputSelect
                placeholder="Virtual Products"
                title="Virtual Products"
                isRequired
                name="virtual_products"
                v-model="form.product_id"
                valueField="product_id"
                textField="name"
                :isValidate="$v.form.product_id.$error"
                :v="$v.form.product_id"
                :options="variantList"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="0" disabled
                    >-- Select Virtual Products --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </b-col>
            <!-- <div style="height: 1px; width: 100%" class="my-3 px-3"> -->
            <!-- <hr class="m-0" /> -->
            <!-- </div> -->
            <div class="break-normal"></div>
            <div class="col-12 main-label text-bold">Custom Fields</div>
            <template v-for="(rows, index) of form.serial_custom_field.answers">
              <template>
                <b-col
                  :key="'tabs-' + index + n"
                  cols="6"
                  v-for="(field, n) of rows.answer_detail"
                >
                  <FieldRender
                    :form="form"
                    :key="field.name"
                    :field="field"
                    :n="n"
                    @onAddImage="
                      (images) =>
                        (form.serial_custom_field.answers[index].answer_detail[
                          n
                        ].value = images)
                    "
                    :index="index"
                  />
                </b-col>
              </template>
            </template>

            <b-col class="col-12 mt-3">
              <InputTextArea
                v-model="form.serial_detail"
                textFloat="Serial Detail"
                placeholder="Serial Detail"
                type="text"
              ></InputTextArea>
            </b-col> </b-row
        ></b-tab>

        <b-tab
          class="content-tabs"
          v-for="(head, form_index) of form.serial_custom_object"
          :key="'head-' + head.serial_custom_object_id"
          :title="head.custom_object_name"
          :value="head.serial_custom_object_id"
        >
          <div class="list-custom-object">
            <template v-for="(ans, answer_index) of head.answers">
              <div
                class="box"
                :key="'answers-' + answer_index"
                @click="
                  addObject(
                    head.serial_custom_object_id,
                    ans.answer_detail,
                    answer_index
                  )
                "
              >
                <b-row>
                  <template
                    v-for="(items, answer_detail_index) of ans.answer_detail"
                  >
                    <b-col
                      :cols="ans.answer_detail.length > 3 ? 6 : 4"
                      :key="'display_field-' + items.id + answer_detail_index"
                      v-if="answer_detail_index < 6"
                    >
                      <b> {{ items.name }}</b> :
                      {{ GetValueField(items) }}
                    </b-col>
                  </template>
                </b-row>
                <div class="panel-icon">
                  <div class="cursor-pointer">
                    <font-awesome-icon
                      icon="pencil-alt"
                      class="text-warning"
                    ></font-awesome-icon>
                  </div>
                  <div
                    class="cursor-pointer"
                    @click.prevent="removeItems(form_index, answer_index)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      class="text-error"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="box add"
              @click="addObject(head.serial_custom_object_id)"
            >
              <font-awesome-icon icon="plus" />
              Add
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-row class="mt-3">
      <b-col cols="6">
        <b-button class="btn-cancel btn-width" variant="dark" @click="hide"
          >Cancel</b-button
        >
      </b-col>
      <b-col cols="6" class="text-sm-right">
        <b-button
          class="main-color btn-save btn-width"
          @click="saveData()"
          :disabled="isLoading"
        >
          Confirm
        </b-button>
      </b-col>
    </b-row>
    <ModalCustomObject ref="serialCustom" @add="addCustomObject" />
  </b-modal>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import SelectBranch from "@/components/inputs/SelectBranch";
import FieldRender from "./FieldRender";
import ModalCustomObject from "./ModalCustomObject";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";

import UploadImage from "@/views/pages/form/report/component/UploadImage.vue";
export default {
  components: {
    OtherLoading,
    ModalCustomObject,
    SelectBranch,
    FieldRender,
    UploadImage,
  },
  props: {
    branchList: {
      required: true,
      type: Array,
    },
    product: {
      required: true,
    },
    isVariant: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      form: {
        branch_id: {
          required,
        },
        serial: {
          required,
        },
        price: {
          required,
        },
        stock_type_id: {
          minValue: minValue(1),
        },
        product_id: {
          required: requiredIf((val) => {
            return this.isVariant;
          }),
          minValue: minValue(1),
        },
      },
    };
  },
  data() {
    return {
      customTabs: 0,
      tabIndex: 0,
      isLoading: false,
      serial_id: "",
      modalShow: false,
      branch_short_name: "",

      form: {
        serial: null,
        serial_detail: "",
        price: null,
        weight: 0,
        product_id: parseInt(this.$route.params.id, 10),
        branch_id: null,
        id: 0,
        stock_type_id: 0,
        serial_custom_field: {
          inventory_product_serial_id: 0,
          answers: [],
        },
        serial_custom_object: [],
      },
      customField: [],

      listStock: [],
      serial_custom_field: {
        inventory_product_serial_id: 0,
        answers: [
          {
            serial_custom_field_answer_id: 0,
            answer_detail: [
              {
                id: 0,
                serial_custom_field_answer_id: 0,
                serial_custom_field_id: 0,
                value: "",
              },
              {
                id: 0,
                serial_custom_field_answer_id: 0,
                serial_custom_field_id: 0,
                value: "",
              },
            ],
          },
        ],
      },
      variantList: [],
      customObjectFields: [],
    };
  },
  methods: {
    async show(items) {
      if (this.isVariant) {
        this.form.product_id = 0;
        this.variantList = this.product.virtual_products.map(
          (el) => el.product
        );
      }
      this.isLoading = true;
      this.serial_id = items ? items.id : 0;
      this.modalShow = true;
      // this.customTabs = 0;
      if (items) {
        const res = await this.axios(
          `/productmenu/get_serial_detail/${this.serial_id}`
        );
        this.form = res.data.detail;

        await this.getSerialCustomField(res.data.detail.serial_custom_field);
      } else {
        await this.getSerialCustomField();
      }
      await this.getSerialCustomObject(items ? items.id : 0);
      this.handleSelectBranch(this.form.branch_id || 0);

      this.isLoading = false;
    },
    async getSerialCustomObject(id) {
      const res = await this.axios(
        `serialproduct/serial_custom_object/answer/${id}`
      );
      this.form.serial_custom_object = res.data.detail;
    },
    async getSerialCustomField(obj) {
      const res = await this.axios(`/serialproduct`);

      this.customField = res.data.detail.map((el) => {
        const defaultValue = el.field_choices
          .filter((fc) => fc.isDefault)
          .map((fc) => fc.id);
        let value = null;

        if (!value || (Array.isArray(value) && value.length === 0)) {
          value = el.field_type_id === 2 ? defaultValue : defaultValue[0] || "";
        }
        return {
          id: 0,
          field_type_id: el.field_type_id,
          name: el.name,
          branch_list: el.branch_list,
          serial_custom_field_answer_id: 0,
          field_choices: el.field_choices,
          isLoading: false,
          serial_custom_field_id: el.id,
          value: value,
        };
      });

      if (obj) {
        for (const element of obj.answers) {
          for (const answer of element.answer_detail) {
            if (answer.field_type_id == 2)
              answer.value = answer.value.split(",");
            if (answer.field_type_id == 3) answer.value = Number(answer.value);
            if (answer.field_type_id == 6) {
              let branch = res.data.detail.find(
                (el) => el.id == answer.serial_custom_field_id
              );
              answer.branch_list = branch.branch_list;
            }
          }
        }
        this.form.serial_custom_field = obj;
      } else {
        this.form.serial_custom_field.answers = [
          {
            serial_custom_field_answer_id: 0,
            answer_detail: JSON.parse(JSON.stringify(this.customField)),
          },
        ];
      }
    },
    async handleSelectBranch(id = 0) {
      const resActive = await this.axios.get(
        `/Inventory/GetActiveStockType/${id}/${this.$route.params.id}`
      );
      if (resActive.data.result) {
        this.listStock = resActive.data.detail;
      }
    },
    async saveData() {
      this.$v.$touch();
      this.isLoading = true;
      for (const element of this.form.serial_custom_field.answers) {
        for (const answer of element.answer_detail) {
          if (answer.field_type_id == 2) {
            answer.value =
              typeof answer.value == "object"
                ? answer.value.join(",")
                : answer.value;
          }
        }
      }
      // console.log(this.form.serial_custom_object.answers);
      for (const items of this.form.serial_custom_object) {
        for (const item of items.answers) {
          for (const answer of item.answer_detail) {
            if (answer.field_type_id == 2) {
              answer.value =
                typeof answer.value == "object"
                  ? answer.value.join(",")
                  : answer.value;
            }
          }
        }
      }
      if (!this.$v.$error) {
        if (this.serial_id === 0) {
          const res = await this.axios.post(
            `/productmenu/create_product_serial`,
            this.form
          );
          if (res.data.result == 1) {
            this.successAlert();
            this.$emit("success");
            this.hide();
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          const res = await this.axios.put(
            `/productmenu/update_product_serial`,
            this.form
          );
          if (res.data.result == 1) {
            this.successAlert();
            this.$emit("success");
            this.hide();
          } else {
            this.errorAlert(res.data.message);
          }
        }
      }
      this.isLoading = false;
    },
    hide() {
      this.form = {
        serial: null,
        serial_detail: "",
        price: null,
        weight: 0,
        product_id: parseInt(this.$route.params.id, 10),
        branch_id: null,
        id: 0,
        stock_type_id: 0,
        serial_custom_field: {
          inventory_product_serial_id: 0,
          answers: [],
        },
      };
      this.modalShow = false;
      this.$v.$reset();
    },
    onKeypressText(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45 ||
        e.charCode == 46
      ) {
      } else {
        e.preventDefault();
      }
    },
    async addFields() {
      const len = this.form.serial_custom_field.answers.length;
      const temp = JSON.parse(JSON.stringify(this.form.serial_custom_field));

      await temp.answers.push({
        serial_custom_field_answer_id: 0,
        answer_detail: JSON.parse(
          JSON.stringify(
            this.customField.map((el) => {
              return { ...el, id: 0 };
            })
          )
        ),
      });
      this.form.serial_custom_field = JSON.parse(JSON.stringify(temp));
      setTimeout(() => {
        this.customTabs = len + 1;
      }, 1);
    },

    addObject(id, obj, activeIndex) {
      this.$refs.serialCustom.show(id, obj, activeIndex);
    },
    addCustomObject(val, index) {
      if (val.every((el) => el.value == "")) return;
      if (index != null) {
        this.form.serial_custom_object[this.tabIndex - 1].answers[
          index
        ].answer_detail = val;
      } else {
        this.form.serial_custom_object[this.tabIndex - 1].answers.push({
          serial_custom_object_answer_id: 0,
          answer_detail: val,
        });
      }
    },
    GetValueField(item) {
      const displayValue = (item) => {
        const value = item.field_choices.filter((el) => {
          let stringValue = String(item.value);
          if (stringValue.includes(","))
            return stringValue.includes(String(el.id));
          else if (typeof stringValue == "object")
            return stringValue.includes(String(el.id));
          else return stringValue == el.id;
        });

        return value ? value.map((el) => el.name).join(",") : "";
      };

      let value =
        item.field_choices.length > 0 ? displayValue(item) : item.value;

      if (item.field_type_id == 2 && typeof item.value == "object")
        return value ? value : "-";
      if (item.field_type_id == 5)
        return item.value
          ? this.$moment(item.value).format("DD/MM/YYYY (HH:mm)")
          : "-";
      if (item.field_type_id == 7)
        return item.value ? this.$moment(item.value).format("DD/MM/YYYY") : "-";
      return value || "-";
    },
    removeItems(form_index, answer_index) {
      this.form.serial_custom_object[form_index].answers.splice(
        answer_index,
        1
      );
    },
  },

  watch: {},
};
</script>
<style lang="scss" scoped>
.fix-height {
  height: 250px !important;
  .h-70 {
    height: unset !important;
  }
}
.custom-width {
  flex: 0 0 100%;
  max-width: 19%;
}
.custom-width-index {
  flex: 0 0 100%;
  max-width: 5%;
}
.custom-field-container {
  max-height: 550px;
  overflow: auto;
}
::v-deep .custom-field-tabs {
  .nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .nav-item {
    min-width: fit-content;
  }
}
.box {
  padding: 1rem;
  position: relative;
  border: 1px dashed var(--primary-color);
  border-radius: 0.25rem;
  cursor: pointer;
  &.add {
    height: 50px;
    text-align: center;
  }
}
.list-custom-object {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
::v-deep .content-tabs {
  height: 450px;
  overflow: hidden auto;
  background-color: white;
}
.panel-icon {
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 10px;
}
::v-deep .nav-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  li.nav-item {
    flex: 0 0 auto;
  }
}
</style>
